export default {
  errors: {
    auth: {
      permission_denied: 'Permission denied',
      permission_denied_details:
        "You don't have permission to access this page",
    },
    limit_reach: "You have reached the maximum of entities on LiveUpdate included in your plan. Contact us to request more entities or turn off an existing entity from LiveUpdate.",   
    readOnly_warning: "Your account is only accessible to view reports. Please contact us to validate entities.",
    zeroRemaining_warning: "Your account has no validations remaining. Please contact us to validate entities.",
    billing_context: {
      invoices: 'Failed to fetch invoices',
      billing_info: 'Failed to fetch billing information',
      plan_subscription: 'Failed to fetch plan details',
      plan_subscription_upgrade: 'Failed to upgrade to new plan',
    },
    twitter: {
      username:
        'Should be between 4-15 alphanumeric characters and underscores',
    },
    must_be_16_years: 'The individual must be 16 years or older.',
    credentials: 'Please check the credentials',
    error: 'Error',
    invalid: 'invalid',
    password_length: 'password should be more than 8 characters',
    no_lowercase: 'should include at least one lowercase letter',
    no_uppercase: 'should include at least one uppercase letter',
    no_number: 'should include at least one number',
    no_active_account: 'no active delegate account associated to this user',
    not_found: 'not found',
    old_password_mismatch:
      'the old password does not match the current password',
    passwords_mismatch: 'passwords do not match',
    required: 'required',
    too_many: 'One value only',
    no_special_chars: 'should include at least one special character',
    reset_token_expired: 'reset token expired',
    server_error: 'server error',
    server_unreachable: 'server unreachable',
    too_long: 'too long',
    too_short: 'must be at least {{count}} characters',
    unauthorized: 'unauthorized',
    talent_already_exist: 'This Talent is already part of your company',
    admin_delegate_already_exist:
      'User <{{email}}> is already an Admin or Delegate in your company',
    admin_delegate_account_exist:
      'This email already has an Admin/Delegate account',
    company_name_exists: 'Company name already exists',
    company_plan_expired: "the company's plan is expired",
    form_submit: 'An error occured while submitting',
    try_again: 'Try again',
    company_alreay_exists: 'Organization already exists',
    too_many_companies: 'More than 3 organizations',
    no_twitter_account_found: 'Twitter Username does not exist',
    invoices: {
      no_invoices: 'No invoices yet',
      fetch_invoices: 'Failed to fetch invoices',
    },
    validation_limit_reached: {
      title: "You've reached your limit",
      description:
        '{{ company }} ran out of validations for their current plan, upgrade to a new plan or purchase a bundle to run a new validation.',
    },
    live_update_not_available: {
      title: 'Unavailable in your current plan',
      description: 'Live update is only available in Business plans.',
      description_SECURITY:
        'Live update is not available in your plan. To upgrade, please email contact@valital.com',
    },
    validations: {
      fetch_individuals: 'Unable to fetch individuals',
      archive_individual: 'Unable to archive selected individual',
      unarchive_individual: 'Unable to activate selected individual',
      delete_individual: 'Unable to delete selected individual',
      new_validation: 'Unable to run the validation',
      live_update: 'Unable to toggle LiveUpdate for this individual',
      fetch_locations: 'Unable to fetch the locations',
      fetch_organisations: 'Unable to fetch the organizations',
      fetch_validatedBy: 'Unable to fetch the validators',
    },
    lev_validations: {
      live_update: 'Unable to toggle LiveUpdate for this organization',
      archive_individual: 'Unable to archive selected organization',
      unarchive_individual: 'Unable to activate selected organization',
      delete_individual: 'Unable to delete selected organization',
    },
    no_organization: 'Title without organization',
  },
  warnings: {
    domain_mismatch: 'Warning: Domain does not match yours',
    personal_email: 'Personal email entered',
    individual_already_entered:
      'Individual details already entered. Please edit the user details or add another user details to proceed.',
    company_field_blank:
      'Adding an Organization name expands the search capabilities. Do you want to proceed without entering this information?',
    country_field_blank:
      'Entering the “City” increases the probability of obtaining a stronger identity match.',
    invalid_twitter_screenname:
      'This Twitter username does not exist. Please edit the entered username or leave the field blank to proceed.',
    cancel_individuals:
      'All the individuals will be deleted. Click CANCEL to proceed.',
    cancel_organizations:
      'All the organizations will be deleted. Click CANCEL to proceed.',
    individual_exists: 'Individual already exists. Submit data to revalidate.',
    last_validated: 'Last validated',
    last_updated:
      'This individual was last validated on {{date}} and will be updated. Edit the form to modify their information or to validate a new individual.',
    lev_last_updated:
      'This organization was last validated on {{date}} and will be updated. Edit the form to modify their information or to validate a new organization.',
    validations: {
      archive: {
        title: 'Archive individual',
        description: 'Are you sure you wish to archive {{name}}?',
      },
      unarchive: {
        title: 'Activate individual',
        description: 'Are you sure you wish to activate {{name}}?',
      },
      delete: {
        title: 'Delete individual',
        description: 'Are you sure you wish to permenantly delete {{name}}?',
      },
    },
    lev_validations: {
      archive: {
        title: 'Archive organization',
        description: 'Are you sure you wish to archive {{name}}?',
      },
      unarchive: {
        title: 'Activate organization',
        description: 'Are you sure you wish to activate {{name}}?',
      },
      delete: {
        title: 'Delete organization',
        description: 'Are you sure you wish to permenantly delete {{name}}?',
      },
    },
  },
  success: {
    request_reference: 'Reference requested',
    talent_invited: 'Talent successfully invited to your company',
    billing: {
      form_success_update: 'Billing information successfully updated!',
      form_success_save: 'Payment details successfully saved!',
    },
    validations: {
      archive_individual: 'Individual successfully archived',
      unarchive_individual: 'Individual successfully activated',
      delete_individual: 'Individual successfully deleted',
      new_validation: 'New validation started',
      live_update:
        'LiveUpdate alerts for this individual are now {{ onOrOff }}',
      update_privacy: 'Privacy status successfully updated',
      update_privacy_private: 'This validation is now private.',
      update_privacy_public: 'This validation is now shared with your team.'
    },
    lev_validations: {
      live_update:
        'LiveUpdate alerts for this organization are now {{ onOrOff }}',
      archive_individual: 'Organization successfully archived',
      unarchive_individual: 'Organization successfully activated',
      delete_individual: 'Organization successfully deleted',
    },
  },
  helpers: {
    year: 'YYYY',
  },
  tooltip: {
    validation_limit_reached: 'Validation limit reached',
    user_limit_reached: 'You reached your limit of users in your plan',
    purchase_bundle: 'Purchase a bundle',
    upgrade_plan: 'Upgrade your plan',
  },
  report: {
    loading: 'Loading...',
    download: 'Download',
    customize: 'Customized report',
    current: 'Current Report',
    full: 'Full Report',
    filters: 'filter(s) applied',
    select: 'Select links',
    links: 'link',
    summary: {
      request_summaries: 'Request Summaries',
      available_credits: '{{count}} summaries available',
      request_sent: 'Your request has been submitted. A member of our team will reach out shortly.',
      request_failed: 'Failed to send summary request'
    },
  },
};
