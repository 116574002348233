// @ts-nocheck
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import type { Node } from 'react';
import { get } from 'lodash';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { useTranslation } from 'react-i18next';
import useAPI from 'hooks/useAPI';
import { useUser, useToken } from './app-context';
import axios from 'axios'
import moment from 'moment';
export const INVOICES_PAGINATION_LIMIT = 15;

const BillingContext = createContext<Context>({
  planSub: null,
  fetchPlanSub: null,
});

export const usePlanSubscription = () => {
  const { planSub } = useContext(BillingContext);  
  if  (planSub && planSub.usedAmount>  planSub.totalAmount) {planSub.usedAmount = 0}
  return { planSub };
};

export const usePlanInfo = () => {
  const { fetchPlanSub } = useContext(BillingContext);
  return { fetchPlanSub };
};

export const BillingProvider = ({ children }: ProviderProps) => {
  const { t } = useTranslation();
  const [planSub, setPlanSub] = useState<PlanSubscription | {} | null>(null);

  const user: CompleteUser | null | void = useUser();
  const token = useToken();
  const API = useAPI();

  // console.log('user', user)
  const companyId = get(user, ['company', 'uuid']);

  const showErrorToaster = useCallback(
    (err: string, isCustom: boolean = false): void =>
      NotificationManager.error(
        // isCustom ? err : t(`validation:errors.billing_context.${err}`),
        t('validation:errors.error')
      ),
    [t]
  );


  const fetchPlanSub = useCallback(async () => {
    if (!companyId || !token) return;

    try {
      const { data: usage } = await API.get(
        `usage`
      );
      var currentDate = moment.utc();

      // Assuming usage.usage.startDate and usage.usage.endDate are in UTC format
      var startDate = moment.utc(usage.usage.startDate);
      var endDate = moment.utc(usage.usage.endDate);

      // Check if the current date is between start and end date
      if (currentDate.isBetween(startDate, endDate)) {
        setPlanSub({ ...usage.usage });
      } else {
        console.log('Plan expired')
        setPlanSub({})
      }
    } catch (err) {
      console.error(err);
      if (err.response) {
        setPlanSub({});
      }
      showErrorToaster('plan_subscription', true);
    }
  }, [API, setPlanSub, showErrorToaster, companyId]);


  useEffect(() => {
    fetchPlanSub();
  }, [fetchPlanSub]);

  return (
    <BillingContext.Provider
      value={{
        planSub,
        fetchPlanSub,
      }}
    >
      {children}
      <NotificationContainer />
    </BillingContext.Provider>
  );
};

type ProviderProps = {
  children: Node;
};

type Context = {
  planSub: PlanSubscription | {} | null;
  fetchPlanSub: (() => Promise<void>) | null;
};
